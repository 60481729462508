<template>
	<div class="">
		<div class="p-sticky bg-white">
			<v-layout class="align-center light-blue-bg pa-2 flex-wrap">
				<v-flex md12 class="d-flex flex-wrap align-center">
					<v-tooltip right>
						<template #activator="{ on, attrs }">
							<h4 class="custom-title font-level-3-bold fw-600 mt-1" v-on="on" v-bind="attrs">
								{{ domain_detail?.name }}
							</h4>
						</template>
						<span>Domain</span>
					</v-tooltip>

					<div
						class="red--text my-auto ml-3 d-flex"
						v-if="date_difference(formatDate_Raw(new Date()), domain_detail.end_date) < 30"
					>
						<h5
							class="mb-0 mr-1"
							v-if="date_difference(formatDate_Raw(new Date()), domain_detail.end_date) >= 0"
						>
							{{
								date_difference(formatDate_Raw(new Date()), domain_detail.end_date) == 0
									? "Expiring:"
									: date_difference(formatDate_Raw(new Date()), domain_detail.end_date) > 0
									? "Expire In:"
									: "Expired"
							}}
						</h5>
						<h5 class="mb-0" style="font-weight: 600">
							{{
								date_difference(formatDate_Raw(new Date()), domain_detail.end_date) == 0
									? "Today"
									: date_difference(formatDate_Raw(new Date()), domain_detail.end_date) > 0
									? date_difference(formatDate_Raw(new Date()), domain_detail.end_date) + " Days"
									: ""
							}}
						</h5>
					</div>

					<div @click.stop class="domain-detail d-flex justify-space-between align-center ml-3">
						<v-select
							v-model="domainstatus"
							solo
							class="listing-select"
							hide-details
							:items="domainStatusList"
							:menu-props="{ offsetY: true, bottom: true }"
						>
							<template v-slot:item="{ item }">
								<v-list-item-content>
									<v-list-item-title
										:style="{ color: item.value == 1 ? 'green' : 'red' }"
										v-text="item.text"
									></v-list-item-title>
								</v-list-item-content>
							</template>
						</v-select>
					</div>

					<v-spacer></v-spacer>

					<template v-if="getPermission('domain:update')">
						<!-- <v-btn
							depressed
							tile
							@click="doAction('renew')"
							class="my-auto mr-3 blue darken-4"
							style="color: #fff"
							:disabled="domain_detail.type == 'client'"
						>
							Renew
						</v-btn> -->

						<v-btn
							depressed
							tile
							@click="doAction('update')"
							class="my-auto mr-3 blue darken-4"
							style="color: #fff"
						>
							Edit
						</v-btn>
					</template>

					<v-btn depressed tile class="my-auto" v-on:click="goBack()">
						<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
						back
					</v-btn>
				</v-flex>

				<v-row class="mt-2 d-flex" style="max-width: 90%">
					<v-col style="max-width: 21.3333% !important" cols="8" class="ml-3">
						<span class="fw-600">Cost: </span>
						<!-- <span class="fw-500">$ {{ domain_detail?.cost }}</span> -->
						<v-chip v-if="domain_detail?.cost" small class="ms-2" outlined label color="green darken-4">
							<div class="mb-0 fw-600" style="font-size: 13px">
								{{ formatMoney(domain_detail?.cost) }}
							</div>
						</v-chip>
						<em class="text-muted" v-else>no cost</em>
					</v-col>
					<v-col style="max-width: 21.3333% !important" cols="8" class="px-0">
						<span class="fw-600">Sales: </span>
						<!-- <span v-if="domain_detail?.sales_price" class="fw-500"
										>$ {{ domain_detail?.sales_price }}</span
									> -->
						<v-chip
							v-if="domain_detail?.sales_price"
							small
							class="ms-2"
							outlined
							label
							color="red darken-4"
						>
							<div class="mb-0 fw-600" style="font-size: 13px">
								{{ formatMoney(domain_detail?.sales_price) }}
							</div>
						</v-chip>
						<em class="text-muted" v-else>no sell price</em>
					</v-col>

					<v-col style="max-width: 21.3333% !important" cols="8" class="px-0">
						<span class="fw-600">Billing Type: </span>
						<!-- <span v-if="domain_detail?.sales_price" class="fw-500"
										>$ {{ domain_detail?.sales_price }}</span
									> -->
						<v-chip v-if="domain_detail?.sales_price" small class="ms-2" label color="cyan darken-4">
							<div class="mb-0 fw-500 white--text">{{ domain_detail?.type }}</div>
						</v-chip>
						<em class="text-muted" v-else>no billing type</em>
					</v-col>
				</v-row>
			</v-layout>

			<v-layout class="ml-6 align-center light-blue-bg px-2">
				<v-row class="" style="max-width: 90%">
					<v-col style="max-width: 20.9% !important" cols="8" class="px-0">
						<span class="fw-600">Start Date: </span>
						<span v-if="domain_detail?.start_date" class="fw-500">{{
							formatDate(domain_detail?.start_date)
						}}</span>
						<em class="text-muted" v-else>no start date</em>
					</v-col>

					<v-col style="max-width: 20.9% !important" cols="8" class="px-0">
						<span class="fw-600">End Date: </span>
						<span v-if="domain_detail?.end_date" class="fw-500">{{
							formatDate(domain_detail?.end_date)
						}}</span>
						<em class="text-muted" v-else>no end date</em>
					</v-col>
				</v-row>
			</v-layout>

			<v-layout class="pa-2 pb-0">
				<v-tabs
					v-model="seoTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent w-100"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#renew"><v-icon small left>mdi-information-outline</v-icon>Renewed History</v-tab>
					<v-tab href="#activity_log"
						><v-icon small left>mdi-google-analytics</v-icon>Activity Log</v-tab
					>
				</v-tabs>
			</v-layout>
		</div>

		<div class="tab-layout-content project-tb-content px-4 mt-1">
			<v-tabs-items v-model="seoTab">
				<v-tab-item v-if="seoTab == 'renew'" value="renew" class="">
					<div class="mt-2 v-data-table bt-table theme--light">
						<div class="v-data-table__wrapper">
							<table class="w-100">
								<thead>
									<tr>
										<th
											v-for="(th, th_idx) in sortedThead"
											:key="th.key + '_' + th_idx + '_' + th.id"
											:width="th.width"
										>
											<template>
												{{ th.name }}
											</template>
										</th>
									</tr>
								</thead>
								<tbody>
									<template v-if="tbody && renew_history.length > 0">
										<tr v-for="(td, td_idx) in renew_history" :key="'domain_' + td.id + '_' + td_idx">
											<template v-for="(th, th_idx) in sortedThead">
												<td :key="'domain_data_$_' + th.key + '_' + th_idx" :width="th.width">
													<template v-if="th.key == 'entity'">
														<div class="d-flex align-center">
															<ImageTemplate
																v-if="td.company_type == 'genic-solution'"
																style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
																src="/media/logos/genic_solution.png"
																circle
																class="mr-2"
															></ImageTemplate>
															<ImageTemplate
																v-if="td.company_type == 'bthrust'"
																style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
																src="/media/logos/bthurst.png"
																circle
																class="mr-2"
															></ImageTemplate>
														</div>
													</template>

													<template v-if="th.key == 'project_details'">
														<div class="">
															<div class="d-flex justify-space-between">
																<label style="width: 80px; font-weight: 600" class="mb-1">Domain : </label>
																<div v-if="td.name">
																	<v-chip class="white--text" label color="teal lighten-1" x-small>
																		{{ td.name }}
																	</v-chip>
																</div>
																<div v-else>
																	<em class="text-muted">no project</em>
																</div>
															</div>
															<div class="d-flex justify-space-between">
																<label style="width: 80px; font-weight: 600" class=""> Status : </label>
																<v-chip
																	class="mb-1 mr-1"
																	label
																	x-small
																	v-if="getValue(project_status, `${getValue(td, 'projectData.status')}.title`)"
																	:color="`${getValue(
																		project_status,
																		`${getValue(td, 'projectData.status')}.color`
																	)} white--text`"
																>
																	<!-- {{ getValue(project_status[getValue(td, "projectData.status")], "title") }} -->
																	{{ getValue(project_status, `${getValue(td, "projectData.status")}.title`) }}
																</v-chip>
																<em v-else class="text-muted">no status</em>
															</div>
															<div class="d-flex justify-space-between mr-1">
																<label style="width: 80px; font-weight: 600" class="mb-1">Sales : </label>
																<div class="d-flex align-center">
																	<!-- <ImageTemplate
																					v-if="td['sales_person'][0]['profile_img']"
																					style="
																						margin-right: 8px;
																						max-width: 30px;
																						height: 30px;
																						box-shadow: 0px 0px 1px 0px #888;
																					"
																					:src="td['sales_person'][0]['profile_img']"
																					circle
																				></ImageTemplate> -->

																	<v-chip
																		label
																		outlined
																		color="red darken-4"
																		x-small
																		v-if="getValue(td, 'sales_person_name')"
																	>
																		{{ getValue(td, "sales_person_name") }}
																	</v-chip>
																	<em v-else>no sales owner</em>
																</div>
															</div>
														</div>
													</template>

													<template v-if="th.key == 'service_provider'">
														<div class="text-center">
															<template v-if="td.service_provider">
																<v-chip label outlined color="cyan darken-4">
																	{{ td.service_provider }}
																</v-chip>
															</template>
															<template v-else>
																<em class="text-muted">no service provider</em>
															</template>
														</div>
													</template>

													<template v-if="th.key == 'billing'">
														<div class="text-center">
															<v-chip
																class="white--text"
																label
																:color="td.type == 'bthurst' ? 'orange' : 'teal lighten-1'"
																>{{ td.type }}
															</v-chip>
														</div>
													</template>

													<template v-if="th.key == 'date'">
														<div>
															<label style="width: 60px; font-weight: 600" class="mb-1">Start :</label>
															<v-chip
																v-if="td.start_date"
																label
																color="orange darken-1 white--text"
																outlined
																text-color=""
															>
																<span class="fw-500"> {{ formatDate(td.start_date) }}</span>
															</v-chip>
															<em class="text-muted" v-else>no start date</em>
														</div>
														<div class="mt-1">
															<label style="width: 60px; font-weight: 600" class="mb-1">End :</label>
															<v-chip v-if="td.end_date" label color="green white--text" outlined text-color="">
																<span class="fw-500"> {{ formatDate(td.end_date) }}</span>
															</v-chip>
															<em class="text-muted" v-else>no end date</em>
														</div>
													</template>

													<template v-if="th.key == 'cost/sales_price'">
														<div class="d-flex">
															<label style="width: 80px; font-weight: 600" class="mb-1">Cost Price : </label>
															<v-chip label color="green white--text" outlined text-color="">
																<span class="fw-500"> {{ formatMoney(td?.cost) }}</span>
															</v-chip>
														</div>
														<div class="d-flex">
															<label style="width: 80px; font-weight: 600" class="mb-1">Sell Price : </label>
															<v-chip
																class="mt-1"
																label
																color="deep-orange lighten-1 white--text"
																outlined
																text-color=""
															>
																<span class="fw-500"> {{ formatMoney(td?.sales_price) }}</span>
															</v-chip>
														</div>
													</template>

													<template v-if="th.key == 'remark'">
														<div
															class="line-clamp-1"
															style="
																max-width: 250px;
																text-wrap: initial;
																display: -webkit-box;
																-webkit-line-clamp: 2;
																-webkit-box-orient: vertical;
																overflow: hidden;
															"
														>
															<ShowTextAreaValue :object="td" object-key="remark" label="remark">
															</ShowTextAreaValue>
														</div>
													</template>

													<template v-if="th.key == 'renew'">
														<div>
															<label style="width: 60px; font-weight: 600" class="mb-1">Date :</label>
															<v-chip
																v-if="td.renew_date"
																label
																color="orange darken-4 white--text"
																outlined
																text-color=""
															>
																<span class="fw-500"> {{ formatDate(td.renew_date) }}</span>
															</v-chip>
															<em class="text-muted" v-else>no renew_date</em>
														</div>
														<div class="mt-1">
															<label style="width: 60px; font-weight: 600" class="mb-1">By :</label>
															<v-chip v-if="td.display_name" label outlined color="green darken-4" text-color="">
																<span class="fw-500"> {{ td.display_name }}</span>
															</v-chip>
															<em class="text-muted" v-else> - </em>
														</div>
													</template>

													<template v-if="th.key == 'last_update'">
														<Chip
															class="mr-1"
															outlined
															:text="td.updated_at"
															textColor="green"
															color="green"
														></Chip>
													</template>
												</td>
											</template>
										</tr>
									</template>
									<template v-if="pageLoading">
										<tr>
											<td colspan="10" class="mx-20 my-40 text-center">
												<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
											</td>
										</tr>
									</template>
									<template v-if="renew_history.length == 0">
										<tr>
											<td :colspan="8">
												<p class="m-0 row-not-found text-center py-3">
													<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
													Uhh... There <span class="text-lowercase">are no file at the moment.</span>
												</p>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>
					<v-row class="mt-2">
						<v-col md="6" class="">
							<p class="m-0">{{ showingString }}</p>
						</v-col>
						<v-col md="6" class="text-right">
							<v-pagination
								color="blue darken-4"
								v-model="page"
								:length="totalPage"
								total-visible="9"
								class="listing-pagination"
								@input="updatePagination"
							></v-pagination>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item v-if="seoTab == 'activity_log'" value="activity_log">
					<div
						class="px-2"
						v-if="activity_log && activity_log.length"
						style="height: calc(100vh - 257px); overflow-y: auto"
					>
						<div
							v-for="(activity, index) in activity_log"
							:key="activity.domain + index"
							class="d-flex flex-column"
							:class="[{ 'mt-3': index == 0 }]"
						>
							<div class="tab-card d-flex justify-content-between pa-3 mb-3 border tab-custom-data">
								<div>
									<div class="d-flex mb-2 fw-500">
										<span class="fw-600 mr-1">Domain</span>
										Activity
									</div>
									<div class="mb-2 fw-500">
										<span class="fw-600 primary--text mr-2" v-if="activity && activity.domain"
											>{{ activity.domain }}
										</span>
										<p v-if="activity && activity.descriptions">{{ activity.descriptions }}</p>
									</div>
								</div>
								<div style="width: 240px" class="date align-items-center">
									<div class="mb-2 fw-500 d-flex justify-content-between">
										<p>Previous :</p>
										<p
											class="fw-600 primary--text mr-2"
											v-if="activity && activity.prev_start && activity.prev_end"
										>
											{{ formatDate(activity.prev_start) }} - {{ formatDate(activity.prev_end) }}
										</p>
										<p class="fw-600 primary--text mr-2" v-else>
											<em class="text-muted">no start or end date</em>
										</p>
									</div>
									<div class="mb-2 fw-500 d-flex justify-content-between">
										<p>Current :</p>
										<p
											class="fw-600 primary--text mr-2"
											v-if="activity && activity.current_start && activity.current_end"
										>
											{{ formatDate(activity.current_start) }} - {{ formatDate(activity.current_end) }}
										</p>
										<p class="fw-600 primary--text mr-2" v-else>
											<em class="text-muted">no start or end date</em>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<template v-else>
						<p class="m-0 row-not-found text-center">
							<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
							Uhh... There are no activity at the moment.
						</p>
					</template>
				</v-tab-item>
			</v-tabs-items>
		</div>

		<UpdateDomain
			v-if="domainUpdateDialog && domainId"
			:detail-id="domainId"
			:updated-dialog="domainUpdateDialog"
			@close="domainUpdateDialog = false"
			@success="domainUpdated"
		>
		</UpdateDomain>

		<RenewDomain
			v-if="domainRenewDialog && domainId"
			:detail-id="domainId"
			:updated-dialog="domainRenewDialog"
			@close="domainRenewDialog = false"
			@success="domainUpdated"
		>
		</RenewDomain>
	</div>
	<!-- </v-sheet> -->
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { toSafeInteger } from "lodash";
import MomentJS from "moment-timezone";
import UpdateDomain from "@/view/module/domain/UpdateDomain.vue";
import RenewDomain from "@/view/components/RenewDomain.vue";
import { GET, QUERY } from "@/core/services/store/request.module";
import ListingMixin from "@/core/mixins/listing.mixin";
import MainMixin from "@/core/mixins/main.mixin";
import { mapGetters } from "vuex";
import HeightMixin from "@/core/mixins/height.mixin";
import { SET_CURRENT_PAGE, SET_SEARCH_BAR } from "@/core/services/store/listing.module";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import ImageTemplate from "@/view/components/Image";
import objectPath from "object-path";

export default {
	name: "Domain-details",
	mixins: [ListingMixin, HeightMixin, MainMixin],
	data() {
		return {
			skipBottomHeight: 48,
			pageLoading: false,
			search_open: false,
			project: {},
			page: 1,
			open_detail: true,
			domain_detail: {},
			credential: false,
			cancelDialog: false,
			customer: {},
			contract: {},
			maintenance: [],
			domainId: 0,
			domainName: null,
			file_list: [],
			seoActivityList: [],
			seoCategoryList: [],
			domainstatus: 1,
			domainStatusList: [
				{
					text: "Active",
					value: 1,
				},
				{
					text: "Expired",
					value: 2,
				},
			],
			domainUpdateDialog: false,
			domainRenewDialog: false,
			activity_log: [],
			renew_history: [],
			sortedThead: [
				{
					id: 219,
					key: "entity",
					name: "Entity",
					width: 200,
				},
				{
					id: 220,
					key: "project_details",
					name: "Project Detail",
					width: 200,
				},
				{
					id: 221,
					key: "service_provider",
					name: "Service Provider",
					width: 200,
				},
				{
					id: 222,
					key: "billing",
					name: "Billing",
					width: 200,
				},
				{
					id: 223,
					key: "date",
					name: "Date",
					width: 200,
				},
				{
					id: 224,
					key: "cost/sales_price",
					name: "Cost/Sell Price",
					width: 200,
				},
				{
					id: 225,
					key: "remark",
					name: "Remark",
					width: 200,
				},
				{
					id: 226,
					key: "renew",
					name: "Renewed Details",
					width: 200,
				},
			],
			endpoint: "domainrenew/",
			project_status: {
				1: { title: "Draft", color: "cyan" },
				2: { title: "Completed", color: "green" },
				3: { title: "Renewed", color: "orange" },
				4: { title: "Cancelled", color: "red" },
			},
		};
	},
	components: {
		UpdateDomain,
		RenewDomain,
		ShowTextAreaValue,
		ImageTemplate,
		// DomainRankingTab,
		// DomainOverviewTab,
		// ImageTemplate,
		// DomainBacklinkTab,
		// RankingReportsTab,
		// URLMappingTab,
		// KeywordAnalysis,
		// KeywordHistory,
		// Analytics,
		// TaskKanban,
		// FilterTask,
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		domainUpdated() {
			this.domainUpdateDialog = false;
			this.domainRenewDialog = false;
			this.getDomainActivityLog();
			this.getSingleDomain();
			this.$nextTick(() => {
				this.filterRows();
			});
		},
		doAction(key = "") {
			switch (key) {
				case "update":
					this.domainUpdateDialog = true;
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "renew":
					this.domainRenewDialog = true;
					break;
			}
		},
		date_difference(start, end) {
			let start_Date = MomentJS(start);
			let end_Date = MomentJS(end);
			let totalDays = end_Date.diff(start_Date, "days");
			return totalDays;
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		getSingleDomain() {
			let domainId = toSafeInteger(this.$route.params.id);
			this.$store
				.dispatch(GET, {
					url: `domain-details/${domainId}`,
				})
				.then((data) => {
					this.domain_detail = { ...data };
					this.domainId = data.id;
					this.domainName = data.name;
					this.getDomainRenewHistory(this.domainName);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getDomainActivityLog(id = this.domainId) {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: `domain-logs?domain_id=${id}` })
				.then((data) => {
					this.activity_log = [...data];
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getDomainRenewHistory(name) {
			const _this = this;

			_this.$store
				.dispatch(QUERY, {
					url: `domainrenew2?name=${name}`,
				})
				.then((data) => {
					console.log("Data received:", data);
					this.renew_history = data.tbody;
				})
				.catch((error) => {
					console.log("Error received:", error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.filterRows();
			});
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
		]),
		seoTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.seoTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "overview";
			},
		},
	},
	beforeMount() {
		let domainId = this.$route.params.id;
		this.endpoint = this.endpoint + domainId;
	},
	mounted() {
		this.getSingleDomain();

		let domainId = this.$route.params.id;
		this.getDomainActivityLog(domainId);
		this.$store.commit(SET_SEARCH_BAR, false);
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Domain", disabled: false, href: "/domain" },
			{ text: "Detail", disabled: true, href: `/domain-detail/${this.domainId}` },
		]);
	},
};
</script>
